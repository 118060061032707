import UserService from '../services/UserService';

export default {
  namespaced: true,
  state: {
    users: [],
    sentEmails: [],
    excelErrors: [],
    editErrors: [],
    addErrors: undefined,
  },
  getters: {
    users: (state) => state.users,
    sentEmails: (state) => state.sentEmails,
    excelErrors: (state) => state.excelErrors,
    editErrors: (state) => state.editErrors,
    addErrors: (state) => state.addErrors,
  },
  mutations: {
    SetUsers(state, users) {
      state.users = users;
    },
    AddSentEmail(state, id) {
      state.sentEmails.push(id);
    },
    SetExcelErrors(state, errors) {
      state.excelErrors = errors;
    },
    SetEditErrors(state, errors) {
      state.editErrors = errors;
    },
    SetAddErrors(state, errors) {
      state.addErrors = errors;
    },
  },
  actions: {
    GetUsers({ commit }) {
      return UserService.GetUsers().then(
        (response) => {
          commit('SetUsers', response.data);
        },
        (error) => {
          commit('CreateError', error, {
            root: true,
          });
        },
      );
    },
    UploadUsers({ commit }, files) {
      return UserService.UploadUsers(files).then(
        (response) => {
          commit('SetExcelErrors', response.data);
        },
        (error) => {
          commit('CreateError', error, {
            root: true,
          });
        },
      );
    },
    GetExcelTemplate({ commit }) {
      return UserService.GetExcelTemplate().then(
        (response) => response,
        (error) => {
          commit('CreateError', error, {
            root: true,
          });
        },
      );
    },
    AddUser({ commit }, user) {
      return UserService.AddUser(user).then(
        (response) => {
          commit('SetAddErrors', response.data);
        },
        (error) => {
          commit('CreateError', error, {
            root: true,
          });
        },
      );
    },
    AddAdmin({ commit }, user) {
      return UserService.AddAdmin(user).then(
        (response) => {
          commit('SetAddErrors', response.data);
        },
        (error) => {
          commit('CreateError', error, {
            root: true,
          });
        },
      );
    },
    EditUser({ commit }, user) {
      return UserService.EditUser(user).then(
        (response) => {
          commit('SetEditErrors', response.data);
        },
        (error) => {
          commit('CreateError', error, {
            root: true,
          });
        },
      );
    },
    EnableUser({ commit }, id) {
      return UserService.EnableUser(id).then(
        (response) => response,
        (error) => {
          commit('CreateError', error, {
            root: true,
          });
        },
      );
    },
    DisableUser({ commit }, id) {
      return UserService.DisableUser(id).then(
        (response) => response,
        (error) => {
          commit('CreateError', error, {
            root: true,
          });
        },
      );
    },
    SendEmail({ commit }, id) {
      return UserService.SendEmail(id).then(
        (response) => {
          commit('AddSentEmail', response.data);
        },
        (error) => {
          commit('CreateError', error, {
            root: true,
          });
        },
      );
    },
    VerifyEmail({ commit }, id) {
      return UserService.VerifyEmail(id).then(
        (response) => response,
        (error) => {
          commit('CreateError', error, {
            root: true,
          });
        },
      );
    },
  },
};
