import axios from 'axios';
import VueCookies from 'vue-cookies';

export default () => {
  const token = VueCookies.get('id_token');

  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  } else {
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  return axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    withCredentials: false, // true
    headers,
  });
};
