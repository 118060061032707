<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-text-field
            v-model="username"
            label="Email"
            :rules="emailRules"
            required></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-text-field
            v-model="password"
            label="Password"
            type="password"
            required>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
        <span
          @click="forgot"
          class="text-link"
        >
          Forgot Password?
        </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-btn @click="submit">Login</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  name: 'LoginForm',
  data: () => ({
    valid: true,
    username: '',
    password: '',
    emailRules: [
      (v) => !!v || 'Username Required',
      (v) => new RegExp(/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/).test(v) || 'Not a valid Email',
    ],
  }),
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        const login = {
          username: this.username,
          password: this.password,
        };
        this.$emit('submit', login);
      }
    },
    forgot() {
      this.$emit('forgot');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
