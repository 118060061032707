import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth';
import upload from './upload';
import accounts from './accounts';
import reports from './reports';
import customers from './customers';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    version: '0.1',
    errors: [],
  },
  getters: {
    hasError: (state) => state.errors.length > 0,
    systemErrors: (state) => state.errors,
  },
  mutations: {
    CreateError(state, error) {
      state.errors.push(error);
    },
    DismissError(state, error) {
      state.errors.splice(state.errors.indexOf(error), 1);
    },
  },
  actions: {
    CreateError(context, error) {
      context.commit('CreateError', error);
    },
    DismissError(context, error) {
      context.commit('DismissError', error);
    },
  },
  modules: {
    auth,
    upload,
    accounts,
    reports,
    customers,
  },
});
