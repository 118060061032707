import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

// binding filters are here for now
Vue.filter('currency', (v) => `${parseFloat(v).toFixed(2)}`);

Vue.filter('bytes', (b) => {
  const kb = b / 1000;
  const mb = kb / 1000;
  const gb = mb / 1000;
  let object;

  if (gb >= 1) {
    object = { key: 'GB', value: gb };
  } else if (mb >= 1) {
    object = { key: 'MB', value: mb };
  } else if (kb >= 1) {
    object = { key: 'KB', value: kb };
  } else {
    object = { key: 'Bytes', value: b };
  }

  return `${object.value} ${object.key}`;
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
