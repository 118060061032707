import AccountService from '../services/AccountService';

export default {
  namespaced: true,
  state: {
    accounts: [],
    url: '',
    raAccounts: [],
    clubAccounts: [],
    terraceAccounts: [],
  },
  getters: {
    accounts: (state) => state.accounts,
    invoiceUrl: (state) => state.url,
    raAccounts: (state) => state.raAccounts,
    clubAccounts: (state) => state.clubAccounts,
    terraceAccounts: (state) => state.terraceAccounts,
  },
  mutations: {
    SetAccounts(state, accounts) {
      state.accounts = accounts;
    },
    SetAccountListItems(state, collection) {
      if (collection.facilityId === 1) {
        state.raAccounts = collection.items;
      } else if (collection.facilityId === 2) {
        state.clubAccounts = collection.items;
      } else if (collection.facilityId === 3) {
        state.terraceAccounts = collection.items;
      }
    },
    SetUrl(state, url) {
      state.url = url;
    },
  },
  actions: {
    GetAccounts({ commit }, query) {
      return AccountService.GetAccounts(query).then(
        (response) => {
          commit('SetAccounts', response.data);
        },
        (error) => {
          commit('CreateError', error, {
            root: true,
          });
        },
      );
    },
    GetAccountListItems({ commit }, facilityId) {
      return AccountService.GetAccountListItems(facilityId).then(
        (response) => {
          commit('SetAccountListItems', { facilityId, items: response.data });
        },
        (error) => {
          commit('CreateError', error, {
            root: true,
          });
        },
      );
    },
    GetInvoicePDF({ commit }, invoiceId) {
      return AccountService.GetInvoicePDF(invoiceId).then(
        (response) => {
          commit('SetUrl', response.data);
        },
        (error) => {
          commit('CreateError', error, {
            root: true,
          });
        },
      );
    },
    PublishInvoices({ commit }, query) {
      return AccountService.Publish(query).then(
        (response) => {
          commit('SetAccounts', response.data);
        },
        (error) => {
          commit('CreateError', error, {
            root: true,
          });
        },
      );
    },
    SendInvoiceEmail({ commit }, { invoiceId, userId }) {
      return AccountService.SendInvoiceEmail(invoiceId, userId).then(
        (response) => response,
        (error) => {
          commit('CreateError', error, {
            root: true,
          });
        },
      );
    },
  },
};
