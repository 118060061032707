<template>
  <v-app
    :dark="false"
    id="app">
    <v-content>
      <BaseAlert
        :showAlert="error"
        :messages="errors"
        :dismissAction="dismissError"></BaseAlert>
      <router-view/>
    </v-content>
  </v-app>
</template>

<script>
import BaseAlert from '@/components/base/BaseAlert.vue';

export default {
  name: 'App',
  components: {
    BaseAlert,
  },
  data: () => ({
    dismissError: '',
  }),
  computed: {
    error() { return this.$store.getters.hasError; },
    errors() { return this.$store.getters.systemErrors; },
  },
  mounted() {
    this.dismissError = 'DismissError';
  },
};
</script>

<style lang="scss">
@import "@/sass/variables.scss";

.text-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
