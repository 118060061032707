import Api from '@/services/Api';
// import axios from 'axios';

// const {CancelToken} = axios; // todo?

const BASE_URL = '/reports';

export default {
  GetAccounts(query) {
    return Api().get(`${BASE_URL}/ViewCounts`, { params: { ...query } });
  },
  GetEmailStatistics(query) {
    return Api().get(`${BASE_URL}/EmailStatistics`, { params: { ...query } });
  },
};
