<template>
  <div>
    <v-snackbar
      v-for="(message, index) in messages"
      :key="index"
      v-model="show"
      color="error"
      top
      right
      :timeout="timeout"
    >
      {{ message }}
      <div>
        <v-btn
          text
          @click.native="dismissError(message)"
          class="pl-2 ml-0">
          <v-icon color="white">fa-times</v-icon>
        </v-btn>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'BaseAlert',
  props: {
    showAlert: {
      type: Boolean,
      default: false,
    },
    messages: {
      type: Array,
      default: () => [],
    },
    dismissAction: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    show: false,
    timeout: 0,
  }),
  watch: {
    showAlert: {
      immediate: true,
      deep: true,
      handler(showAlert) {
        if (showAlert) {
          this.show = true;
        } else {
          this.show = false;
        }
      },
    },
  },
  methods: {
    dismissError(msg) {
      const action = this.$props.dismissAction;
      this.$store.dispatch(action, msg, {
        root: true,
      });
      if (!this.$store.getters.hasError) {
        this.show = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>
