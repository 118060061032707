import ReportsService from '../services/ReportService';

export default {
  namespaced: true,
  state: {
    accounts: [],
    emailStats: {},
  },
  getters: {
    accounts: (state) => state.accounts,
    emailStats: (state) => state.emailStats,
  },
  mutations: {
    SetAccounts(state, accounts) {
      state.accounts = accounts;
    },
    SetEmailStatistics(state, stats) {
      state.emailStats = stats;
    },
  },
  actions: {
    GetAccounts({ commit }, query) {
      return ReportsService.GetAccounts(query).then(
        (response) => {
          commit('SetAccounts', response.data);
        },
        (error) => {
          commit('CreateError', error, {
            root: true,
          });
        },
      );
    },
    GetEmailStatistics({ commit }, query) {
      return ReportsService.GetEmailStatistics(query).then(
        (response) => {
          commit('SetEmailStatistics', response.data);
        },
        (error) => {
          commit('CreateError', error, {
            root: true,
          });
        },
      );
    },
  },
};
