import Api from '@/services/Api';
// import axios from 'axios';

// const {CancelToken} = axios; // todo?

const BASE_URL = '/accounts';

export default {
  GetAccounts(query) {
    return Api().get(`${BASE_URL}`, { params: { ...query } });
  },
  GetAccountListItems(facilityId) {
    return Api().get(`${BASE_URL}/${facilityId}/ListItems`);
  },
  GetInvoicePDF(invoiceId) {
    return Api().get(`${BASE_URL}/Invoice/${invoiceId}`);
  },
  Publish(query) {
    return Api().post(`${BASE_URL}/Invoices/${query.facilityId}/${query.month}/${query.year}`);
  },
  SendInvoiceEmail(invoiceId, userId) {
    return Api().post(`${BASE_URL}/Invoice/${invoiceId}/SendEmail/${userId}`);
  },
};
