<template>
  <v-container fluid>
    <v-row>
      <v-col cols=12>
        <v-row
          align="center"
          justify="center"
        >
          <LoginCard></LoginCard>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginCard from '@/components/login/LoginCard.vue';

export default {
  name: 'Login',
  components: {
    LoginCard,
  },
};
</script>
