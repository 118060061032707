import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#B1BA8A', // '#ee44aa',
        secondary: '#D9B983', // '#424242',
        accent: '#388D80', // '#82B1FF',
        error: '#97464A', // '#895452', // #FF5252',
        info: '#2C2730', // '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
  icons: {
    iconfont: 'mdi' || 'fa',
  },
});
