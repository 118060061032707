import Api from '@/services/Api';
// import axios from 'axios';

// const {CancelToken} = axios; // todo?

const BASE_URL = '/auth';

export default {
  Login(login) {
    return Api().post(`${BASE_URL}/login`, login);
  },
  GetTokenInfo(token) {
    const model = {
      token,
    };
    return Api().post(`${BASE_URL}/tokenInfo`, model);
  },
};
