<template>
  <v-container fluid>
    <v-row>
      <v-col cols=12>
        Login Successful
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueCookies from 'vue-cookies';

export default {
  name: 'LoginSuccess',
  mounted() {
    const parsedHash = new URLSearchParams(this.$route.hash.substr(1));
    const token = parsedHash.get('id_token');
    VueCookies.set('id_token', token); // 1h
    this.$store.dispatch('auth/GetTokenInfo', token);
    this.$store.dispatch('auth/SetSessionToken', token);
    this.$router.push({ name: 'Services' });
  },
};
</script>
