import Api from '@/services/Api';
// import axios from 'axios';

// const {CancelToken} = axios; // todo?

const BASE_URL = '/users';

export default {
  UploadUsers(files) {
    const form = new FormData();
    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      form.append('files', file, file.name);
    }

    return Api().post(`${BASE_URL}/Upload`, form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  GetUsers() {
    return Api().get(`${BASE_URL}`);
  },
  GetExcelTemplate() {
    return Api().get(`${BASE_URL}/Template`, {
      responseType: 'arraybuffer',
    });
  },
  AddUser(user) {
    return Api().post(`${BASE_URL}/Add`, user);
  },
  AddAdmin(user) {
    return Api().post(`${BASE_URL}/AddAdmin`, user);
  },
  EditUser(user) {
    return Api().put(`${BASE_URL}/Edit`, user);
  },
  DisableUser(id) {
    return Api().put(`${BASE_URL}/${id}/Disable`);
  },
  EnableUser(id) {
    return Api().put(`${BASE_URL}/${id}/Enable`);
  },
  SendEmail(id) {
    return Api().post(`${BASE_URL}/${id}/Email`);
  },
  VerifyEmail(id) {
    return Api().post(`${BASE_URL}/${id}/Verify`);
  },
};
