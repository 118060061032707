import Api from '@/services/Api';
// import axios from 'axios';

// const {CancelToken} = axios; // todo?

const BASE_URL = '/upload';

export default {
  Upload(model) {
    const form = new FormData();
    // eslint-disable-next-line no-restricted-syntax
    for (const file of model.files) {
      form.append('files', file, file.name);
    }

    return Api().post(`${BASE_URL}/${model.facilityId}/${model.year}/${model.month}`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  FinalizeUpload(sessionErrors) {
    return Api().post((`${BASE_URL}/FinalizeUpload/${sessionErrors.uploadSessionId}`), sessionErrors);
  },
};
