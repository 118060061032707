<template>
  <v-card>
    <LoginForm
      @submit="submit($event)"
      @forgot="forgot"
    >
    </LoginForm>
  </v-card>
</template>

<script>
import LoginForm from './login-card/LoginForm.vue';

export default {
  name: 'LoginCard',
  components: {
    LoginForm,
  },
  methods: {
    submit() {
      this.$router.push({ name: 'Services' });
    },
    forgot() {
      this.$router.push({ name: 'Forgot' });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.v-card {
  width: 400px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
