import VueCookies from 'vue-cookies';
import AuthService from '../services/AuthService';

export default {
  namespaced: true,
  state: {
    token: VueCookies.get('id_token') || '',
    role: VueCookies.get('role') || '',
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    role: (state) => state.role,
  },
  mutations: {
    SetSessionToken(state, token) {
      this.token = token;
    },
    SetTokenInfo(state, tokenInfo) {
      this.role = tokenInfo.role;
      VueCookies.set('role', tokenInfo.role); // 1h
    },
    Logout(state) {
      state.token = '';
      VueCookies.remove('id_token');
    },
  },
  actions: {
    SetSessionToken({ commit }, token) {
      commit('SetSessionToken', token);
    },
    GetTokenInfo({ commit }, token) {
      return AuthService.GetTokenInfo(token).then(
        (response) => {
          commit('SetTokenInfo', response.data);
        },
        (error) => {
          commit('CreateError', error, {
            root: true,
          });
        },
      );
    },
  },
};
