import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from '@/views/Login.vue';
import NotFound from '@/views/NotFound.vue';
import LoginSuccess from '@/views/LoginSuccess.vue';

import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: 'Login',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter() {
      const loginUrl = `${process.env.VUE_APP_LOGIN_URL}/login?response_type=token&client_id=${process.env.VUE_APP_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_LOGIN_SUCCESS}`;

      window.location = loginUrl;
    },
  },
  {
    path: '/login-success',
    name: 'LoginSuccess',
    component: LoginSuccess,
  },
  {
    path: '/services',
    redirect: { name: 'InvoiceUpload' },
    name: 'Services',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "services" */ '../views/Nav.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '/invoiceUpload',
        name: 'InvoiceUpload',
        component: () => import(/* webpackChunkName: "services" */ '../views/services/InvoiceUpload.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/accounts',
        name: 'Accounts',
        component: () => import(/* webpackChunkName: "services" */ '../views/services/Accounts.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/reports',
        name: 'Reports',
        component: () => import(/* webpackChunkName: "services" */ '../views/services/Reports.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/customers',
        name: 'Customers',
        component: () => import(/* webpackChunkName: "services" */ '../views/services/Customers.vue'),
      },
      {
        path: '/customers/edit',
        name: 'EditCustomer',
        component: () => import(/* webpackChunkName: "services" */ '../views/services/customers/EditCustomer.vue'),
        props: (route) => ({
          user: route.params.user,
        }),
      },
    ],
  },
  {
    path: '*',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/isLoggedIn'];

  if (to.meta.requiresAuth) {
    if (isAuthenticated) {
      next();
    } else {
      next({ name: 'Login' });
    }
  } else {
    next();
  }
});

export default router;
