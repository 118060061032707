import UploadService from '@/services/UploadService';

export default {
  namespaced: true,
  state: {
    uploadSessionId: 0,
    uploadSessionErrors: {
      NotFoundErrors: [],
      TotalErrors: [],
      FacilityErrors: [],
    },
  },
  getters: {
    uploadSessionId: (state) => state.uploadSessionId,
    uploadSessionErrors: (state) => state.uploadSessionErrors,
  },
  mutations: {
    SetUploadSessionId(state, uploadSessionId) {
      state.uploadSessionId = uploadSessionId;
    },
    SetUploadSessionErrors(state, errors) {
      state.uploadSessionErrors = errors;
    },
  },
  actions: {
    Upload({ commit }, model) {
      return UploadService.Upload(model).then(
        (response) => {
          commit('SetUploadSessionId', response.data.uploadSessionId);
          commit('SetUploadSessionErrors', response.data);
        },
        (error) => {
          commit('CreateError', error, {
            root: true,
          });
        },
      );
    },
    FinalizeUpload({ commit }, sessionErrors) {
      return UploadService.FinalizeUpload(sessionErrors).then(
        () => {
          commit('SetUploadSessionId', 0);
        },
        (error) => {
          commit('CreateError', error, {
            root: true,
          });
        },
      );
    },
  },
};
